<template>
  <ion-page class="ion-page">
    <GoBackHeader defaultHref="/check-in/training" />

    <ion-content>
      <Refresher @refresh="doRefresh($event, 'getCheckedInRiders')" />

      <List
        :header="headerTitle"
        :not-found-message="$t('noriderscheckedinyet')"
      >
        <template v-if="getCheckedInRiders && getCheckedInRiders.length">
          <ion-item-sliding v-for="rider in getCheckedInRiders" :key="rider.id">
            <ion-item>
              <ion-thumbnail slot="start">
                <img :src="`${$apiStorageUrl}/${rider.profile_image}`">
              </ion-thumbnail>
              <ion-label>
                <h2>{{ rider.first_name }} {{ rider.last_name }}</h2>
                <h3>{{ $t('yearsold', { age: getAge(rider.date_of_birth) }) }}  </h3>
              </ion-label>
            </ion-item>
            <ion-item-options side="end">
              <ion-item-option color="danger" @click="uncheckRider(rider.id)">
                <ion-icon name="trash" color="white" /> {{ $t('deletecheckin') }}
              </ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        </template>
      </List>
    </ion-content>

    <ion-footer>
      <ion-toolbar>
        <ion-button expand="full" @click="goToScanner">
          <ion-icon name="camera" />
          <ion-text>{{ $t('checkinnewrider') }}</ion-text>
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>
<script>
  import * as moment from 'moment';
  import {CHECKED_IN_RIDERS, GET_EVENT} from "../../graphql/queries";
  import {REMOVE_CHECKEDIN_RIDER} from "../../graphql/mutations";
  import { infiniteScrollMixin, refreshMixin } from '@/mixins';
  import GoBackHeader from '../../components/navigation/GoBackHeader.vue';
  import { mapGetters } from "vuex";
  import { GET_EVENT_ID_GETTER } from "@/store/store-getters";

  export default {
    components: { GoBackHeader },
    mixins: [
      infiniteScrollMixin,
      refreshMixin
    ],
    data() {
      return {
        error: ''
      }
    },
    computed: {
      ...mapGetters({eventId: GET_EVENT_ID_GETTER}),
      headerTitle() {
        return (this.getCheckedInRiders && this.getCheckedInRiders.length) ? this.$i18n.t('checkedinriders', { number: this.getCheckedInRiders.length }) : this.$i18n.t('checkedinriders', { number: 0 });
      }
    },
    beforeCreate() {
      document.body.style.backgroundColor = '';
      document.documentElement.style.backgroundColor = '';
    },
    methods: {
      getAge(date) {
        return moment().diff(moment(date, 'YYYY-MM-DD'), 'years');
      },
      showAlert() {
        return this.$ionic.alertController
          .create({
            header: 'Melding',
            message: 'QR Codes scannen is only available by App. Download it through App Store and Play Store',
            buttons: ['OK'],
          })
          .then(a => a.present())
      },
      goToScanner() {
        this.$router.push({name: this.$routeNames.SCANNER})
      },
      async uncheckRider(id) {
        await this.$apollo.mutate({
          mutation: REMOVE_CHECKEDIN_RIDER,
          variables: {
            user_id: id,
            event_id: this.eventId
          }
        });

        this.$apollo.queries.getCheckedInRiders.refetch()
      }
    },
    apollo: {
      getCheckedInRiders: {
        query: CHECKED_IN_RIDERS,
        fetchPolicy: 'network-only',

        variables() {
          return {
            event_id: this.eventId
          }
        },
        error(error) {
          this.error = error.message
        }

      },
      event: {
        query: GET_EVENT,
        variables() {
          return {
            id: this.eventId
          }
        }
      }
    }
  }
</script>
